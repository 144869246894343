import React from 'react';
import { Box, Text, Button, Center, Divider, useDisclosure } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';
import Content from './Content';

import txtWording from './locales';
import useLang from '@/hooks/useLang';

import { IMG_LOVE_STORY } from '@/constants/assets';
import { THE_BRIDE } from '@/constants';
import { ENABLE_LOVE_STORY_CONTENT } from '@/constants/feature-flags';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function LoveStory() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const defaultLang = useLang();
  const lang = defaultLang;
  
  return (
    <Box bgColor="bgPrimary">
      {/* Image Cover */}
      <Divider maxW="45%" borderBottomColor="white" opacity={1} borderBottomWidth="3px" />
      <Box position="absolute" maxW="500px" width="100%">
        <Divider
          position="absolute"
          right="0"
          maxW="45%"
          borderBottomColor="white"
          opacity={1}
          borderBottomWidth="3px"
          zIndex={2}
          transform="translateY(250px)"
        />
      </Box>
      <Box padding="32px 32px 16px 32px" paddingBottom="42px">
        <Box>
          <WithAnimation>
            <Center>
              <Image
                src={IMG_LOVE_STORY}
                objectFit="cover"
                objectPosition="center"
                height="270px"
                width="270px"
              />
            </Center>
          </WithAnimation>
          <Box
            textShadow="1px 1px 1px #7A7A7A"
            color="mainColorText"
            textAlign="center"
            zIndex={2}
            position="absolute"
            transform="translateY(-24px)"
            width="90%"
            maxWidth="500px"
          >
            <Text fontSize="xl" bottom="16px">
              I WILL MARRY WITH
            </Text>
            <Text marginTop="-8px" paddingLeft="16px" fontSize="xl" bottom="16px">
              THE LOVE OF MY LIFE
            </Text>
            <Text marginTop="-8px" fontSize="3xl" fontFamily="heading">
              {THE_BRIDE}
            </Text>
          </Box>
        </Box>
        <Box height="40px" />
        <WithAnimation>
          <Box padding="0 8px">
            <Box>
              <Box height="24px" />
              <WithAnimation>
              <Text margin="12px 0" fontSize="md" color="white" textAlign="center">
                  {`Year after year passed and brought us closer, we became convinced that we were meant to be together. It wasn't easy for us to get to this point, but it was all worth it.`}
                </Text>
              </WithAnimation>
              {ENABLE_LOVE_STORY_CONTENT && (
                <WithAnimation>
                  <Center margin="16px 0">
                    <Button
                      colorScheme="blackAlpha"
                      color="black"
                      bgColor="mainColor"
                      fontFamily="body"
                      size="sm"
                      fontWeight="light"
                      {...DEFAULT_BUTTON_PROPS}
                      onClick={() => onOpen()}
                    >
                      {txtWording.loveStory[lang]}
                    </Button>
                  </Center>
                </WithAnimation>
              )}
            </Box>
          </Box>
        </WithAnimation>
      </Box>

      <Content onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}

export default LoveStory;
